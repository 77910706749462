<template lang="pug">
  v-dialog(:value="value" @input="valueUpdated"  :width="340")
    v-card
      v-card-title(data-test="location-title") Select course location options
      v-card-text
        filter-autocomplete(
          :getItems="getCourseLocations"
          v-model="formData.locations"
          multiple
          label="Course location"
          item-value="ID"
          item-text="name"
          data-test="location-modal"
          :error="formErrors.hasError('location')"
          :error-messages="formErrors.fields.location"
        ).mr-4.c-accept-product
        v-autocomplete(
          v-model="formData.products"
          label="Products"
          multiple
          item-value="name"
          item-text="name"
          :items="products"
        )
      v-card-actions
        v-spacer
        v-btn(color="error" text @click="close") Close
        v-btn(color="success" :loading="processing" text @click="submitData" data-test="location-submit") Accept
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import FormErrors from "@/util/form-errors"

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    processing: Boolean,
    model: Object
  },

  data: () => ({
    showing: false,
    locations: [],
    formData: {
      locations: [],
      products: []
    },
    formErrors: new FormErrors()
  }),

  computed: {
    ...mapGetters({
      products: 'crmProductNames/items'
    })
  },

  methods: {
    ...mapActions({
      getCourseLocations: 'crmCourseCities/list'
    }),

    valueUpdated(val) {
      this.$emit('input', val)
    },

    setDefault() {
      if (!this.model) return
      this.formData.locations = this.model.courseLocations
      this.formData.products = this.model.products
    },

    submitData() {
      this.$emit('submit', this.formData)
    },

    close() {
      this.valueUpdated(false)
    }
  },

  watch: {
    value(isShowing) {
      if (isShowing) this.setDefault()
    },
  },

  components: {
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
  }
}
</script>